import React, { useState, useEffect } from "react"

import "./FadeIn.scss"

const FadeIn = ({ src, alt, right }) => {
  let element
  const [fadedIn, setFadedIn] = useState(false)

  function handleScroll() {
    if (fadedIn || !element) return
    if (element.getBoundingClientRect().top + 40 < window.innerHeight) {
      setFadedIn(true)
    }
  }

  useEffect(() => {
    handleScroll()
    document.addEventListener("scroll", handleScroll)
    return () => document.removeEventListener("scroll", handleScroll)
  })

  return (
    <div
      className={`img-container ${fadedIn ? "faded-in" : ""} ${
        right ? "float-sm-right" : "float-sm-left"
      }`}
      ref={el => (element = el)}
    >
      <img src={src} alt={alt} className="p-4 img-fluid" />
    </div>
  )
}
export default FadeIn
