import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/Form"
import FadeIn from "../components/FadeIn"

import Scrabble from "../images/scrabble.png"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="row">
      <div className="col-md-6">
        <h1>Contact</h1>
        <p>
          Bent u benieuwd wat Balans in hoofd en huis voor u kan betekenen? Neem
          dan gerust contact op. Ik vertel u graag wat de mogelijkheden zijn.
        </p>
        <address>
          E-mail:{" "}
          <a href="mailto:info@balansinhoofdenhuis.nl">
            info@balansinhoofdenhuis.nl
          </a>
          <br />
          Telefoon: <a href="tel:+31640889168">06 408 891 68</a>
        </address>
        <p>
          Als u het contactformulier invult, neem ik zo spoedig mogelijk contact
          met u op.
        </p>

        <FadeIn src={Scrabble} alt="Balans in hoofd en huis"/>
      </div>

      <div className="col-md-6">
        <Form />
      </div>
    </div>
  </Layout>
)

export default Contact
