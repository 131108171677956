import React from "react"

import "./alert.css"

export default function Alert({ message, type }) {
  if (!message) return null

  return (
    <div class={"alert alert-dismissible fade show " + type} role="alert">
      {message}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}
