import React, { useState } from "react"
import Alert from "./Alert"

function Form() {
  const [alert, setAlert] = useState("Test alert message")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [question, setQuestion] = useState("")
  const [privacyAccepted, setPrivacyAccepted] = useState("")

  function resetForm() {
    setName("")
    setEmail("")
    setPhone("")
    setQuestion("")
    setPrivacyAccepted("")
  }

  function NoEmptyField() {
    return (
      name.length &&
      email.length &&
      phone.length &&
      question.length &&
      privacyAccepted
    )
  }

  function submitForm() {
    if (!NoEmptyField()) {
      return setAlert({
        message: "Niet alle velden zijn correct in gevuld.",
        type: "alert-danger",
      })
    }
    const data = new FormData()
    data.set("Naam", name)
    data.set("Email", email)
    data.set("Telefoonnummer", phone)
    data.set("Vraag", question)
    const xhr = new XMLHttpRequest()
    xhr.open("POST", "https://formspree.io/mrgylbgp")
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        setAlert({
          message:
            "Het bericht is verstuurd! U ontvangt zo spoedig mogelijk antwoord.",
          type: "alert-success",
        })
        resetForm()
      } else {
        setAlert({
          message:
            "Er is iets misgegaan! Probeert u het later opnieuw, of neem contact op via e-mail of telefoon.",
          type: "alert-danger",
        })
      }
    }
    xhr.send(data)
  }

  return (
    <>
      <Alert {...alert} />
      <div className="card p-4">
        <form>
          <div className="form-group">
            <label htmlFor="name">Naam</label>
            <input
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
              name="name"
              className="form-control"
              id="name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">E-mailadres</label>
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="text"
              name="email"
              className="form-control"
              id="email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Telefoonnummer</label>
            <input
              value={phone}
              onChange={e => setPhone(e.target.value)}
              type="text"
              name="phone"
              className="form-control"
              id="phone"
            />
          </div>

          <div className="form-group">
            <label htmlFor="question">Mijn vraag</label>
            <textarea
              value={question}
              onChange={e => setQuestion(e.target.value)}
              type="text"
              name="question"
              className="form-control"
              id="question"
            />
          </div>

          <div className="form-check">
            <input
              name="privacy-policy-accepted"
              type="checkbox"
              className="form-check-input"
              checked={privacyAccepted}
              id="checkPrivacy"
              onChange={e => setPrivacyAccepted(!privacyAccepted)}
            />
            <label className="form-check-label" htmlFor="checkPrivacy">
              Ik ga akkoord met de{" "}
              <a href="/privacy-policy" target="blank">
                privacy policy
              </a>
            </label>
          </div>

          <div className="mt-4">
            <button
              type="button"
              onClick={submitForm}
              className="btn btn-danger float-right"
            >
              Versturen
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Form
